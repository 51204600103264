// Bootstrap Framework
import './scss/index.scss';

import jquery from 'jquery';
window.$ = window.jQuery = jquery;

import 'popper.js';
import 'bootstrap';

import "slick-carousel";

import '@fancyapps/fancybox';



// Tailwind CSS Framework
//import './pcss/index.pcss';

import "slick-carousel";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

//#region bootstrap navbar + hamburger + body scroll lock
$('#navbarMain').on('show.bs.collapse', function(e) {
    $('.navbar-toggler .hamburger').addClass('is-active');
    let scrollableNav = document.querySelector('#navbarMain');
    disableBodyScroll(scrollableNav);
});
$('#navbarMain').on('hidden.bs.collapse', function(e) {
    $('.navbar-toggler .hamburger').removeClass('is-active');
    let scrollableNav = document.querySelector('#navbarMain');
    enableBodyScroll(scrollableNav);
});
//#endregion

//#region bootstrap navbarSIDE 
/* $('#navbarSide').on('show.bs.collapse', function(e) {
    $('.navbar-toggler .hamburger').addClass('is-active');
    let scrollableNav = document.querySelector('#navbarSide');
    disableBodyScroll(scrollableNav);
});
$('#navbarSide').on('hidden.bs.collapse', function(e) {
    $('.navbar-toggler .hamburger').removeClass('is-active');
    let scrollableNav = document.querySelector('#navbarSide');
    enableBodyScroll(scrollableNav);
}); */
//#endregion




document.addEventListener("DOMContentLoaded", function() {
    /////// Prevent closing from click inside dropdown
    document.querySelectorAll('.dropdown-menu').forEach(function(element) {
        element.addEventListener('click', function(e) {
            e.stopPropagation();
        });
    })


});

$(function() {

    //#region home slider
    $('.slider').slick({
            prevArrow: '.slider__arrow--prev',
            nextArrow: '.slider__arrow--next',
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 1000,
            fade: true,
        })
        //#endregion

    //#region window resizing trigger
    $(window).on('resize', function() {
        navbarCollapseMobileConfig();
        $('.slider').slick('refresh');
    });
    //#endregion


});